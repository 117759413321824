import React, { Component } from 'react';
import './RefundAndRetunpolicy.css';

class RefundAndRetunpolicy extends Component {
  render() {
    return (
      <div className="terms-and-conditions">
        <h2>Return Policy</h2>
        <p>Product must be returned to us within 10 days from the date it has been delivered to the customer. Product must be returned with all tags attached in its original condition along with all packing material, courier receipt, invoice & other papers.</p>

        <h2>Refund Policy</h2>
        <p>Once the Product is received to the company successfully, Comtal Technologies will instantly initiate the refund to your source account or chosen method of refund.</p>

        <h2>Refund and Cancellation for Service Provider Company</h2>
        <p>Due to service providers in nature “NO REFUND”, “NO CANCELLATION” will be entertained once the Payment has been made.</p>

        <h2>Cancellation Policy</h2>
        <p>Please note an order can only be canceled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained. However, return is possible for all orders/products.</p>
        <p>OR</p>
        <p>Customers can CANCEL the order only before the Order has been shipped/Dispatched. After the Product/s have been shipped, The Customer CANNOT Cancel the Orders. However, return is possible for all orders/products.</p>

        <h2>Shipping & Delivery Policies</h2>
        <p>Comtal Technologies ships its products to almost all parts of India. Orders placed will be shipped within 24* hrs. We ship on all days except Sunday and National Holidays. For all areas serviced by reputed couriers, the delivery time would be within 3 to 4 business days of shipping (business days exclude Sundays and other holidays). For other areas, the products will be shipped through courier service or service person and may take 1-2 weeks depending on location. At times there might be unexpected delays in the delivery of your order due to unavoidable and undetermined logistics challenges beyond our control for which Comtal Technologies is not liable and would request its users to cooperate as Comtal Technologies continuously tries to avoid such instances. Also, Comtal Technologies reserves the right to cancel your order at its sole discretion in cases where it takes longer than usual delivery time or the shipment is physically untraceable and refund the amount paid for canceled product(s) to your source account.</p>

        <h2>Contact Us</h2>
        <p>Comtal Technologies<br />
        No-41/2,42/2,2nd Cross Street,<br/>
        THIRU VI GA,Thundalam,Chennai,<br/>
        Tamil Nadu 600011<br />
         +91 9159153450<br />
         helpdesk@comtal.in</p>
      </div>
    );
  }
}

export default RefundAndRetunpolicy;
