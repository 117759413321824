import React from 'react';
import './App.css';
import Home from './Home';
import Topheader from './Topheader';
import Header from './Header';
import Slider from './Slider';
import About from './About';


// import Leftcontent from './Leftcontent';
// import Rightcontent from './Rightcontent';
// import Services from './Services';


import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import TermsAndConditions from './TermsAndConditions';
import RefundAndRetunpolicy from './RefundAndReturnPolicy';
function App() {
return (
<Router>
   <div className="App">
      <Switch>

         <Route path="/" exact component={Home}></Route>

         <Route path="/topheader">
            <Topheader/>
         </Route>
   
         <Route path="/header">
            <Header/>
         </Route>  

         <Route path="/slider">
            <Slider/>
         </Route>  

         <Route path="/About">
            <About/>
         </Route>  
         <Route path="/privacypolicy">
         <TermsAndConditions/>
         </Route>
         <Route path="/refundandretunpolicy">
         <RefundAndRetunpolicy/>
         </Route>


         {/* <Route path="/Leftcontent">
            <Leftcontent/>
         </Route> 
     
         <Route path="/Rightcontent">
            <Rightcontent/>
         </Route>  
         <Route path="/Services">
            <Services/>
         </Route>   */}



      </Switch>
   </div>
</Router>




);
}
export default App;