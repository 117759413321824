import React from 'react';
import ChatBot from 'react-simple-chatbot';



class WhatWeDo extends React.Component{
render()
{
return (
<div>

<footer class="footer-top-area pt-100 pb-70" id="Contact">
         <div class="container">
            <div class="row">
               <div class="col-lg-4 col-md-6">
                  <div class="single-widget">
                     <a href="index.html">
                     <img  src="./assets/images/logo-footer.svg" style={{width:"175px"}} alt="Logo"/>
                     </a>
                     <p>Forget the stress of buying, maintaining, and upgrading laptops.
Comtal Technologies offers the power of flexible, hassle-free rentals,
perfect for any project or situation.</p>
                   <br/>
                    <p> Say goodbye to hefty price tags
and hello to reliable, high-performance machines delivered straight
to your door.</p>
<br/>
<p>Rent smarter, work smarter, and leave the tech worries behind, our
24/7 hardware support is always there to keep you up and running.</p>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6">
                  <div class="single-widget contact">
                     <h3>Contact Us</h3>
                     <ul>
                        <li class="pl-0">
                           <a href="#">
                           <i class='bx bx-phone-call' ></i>
                           <span> Phone:</span>
                           +91  9159153450
                           </a>
                        </li>
                        <li class="pl-0">
                           <a href="#">
                           <i class='bx bx-mail-send'></i>
                           <span>Email:</span>
                           <span class="__cf_email__"></span>
                           helpdesk@comtal.in
                           </a>
                        </li>
                        <li>
                        <i class='bx bx-home-alt' ></i>
                           <span>Address:</span>
                           No-41/2,42/2,2nd Cross Street,<br/>
                           THIRU VI GA,Thundalam,Chennai,<br/>Tamil Nadu
                            600011
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6">
                  <div class="single-widget">
                     <h3>Our Service</h3>
                     <ul>
                        <li>
                           <a href="#Services">
                           <i class='bx bx-chevron-right'></i>
                           Desktop Rental
                           </a>
                        </li>
                        <li>
                           <a href="#Services">
                           <i class='bx bx-chevron-right'></i>
                           Laptop Rental
                           </a>
                        </li>
                        <li>
                           <a href="#Services">
                           <i class='bx bx-chevron-right'></i>
                           24/7 Hardware Support
                           </a>
                        </li>
                        <li>
                           <a href="#Services">
                           <i class='bx bx-chevron-right'></i>
                           Door-Step Delivery
                           </a>
                        </li>
                          <h3 class="single-widget">Privacy Policy</h3>
                        <li>
                           <a href="/privacypolicy">
                           <i class='bx bx-chevron-right'></i>
                           Terms And Conditions
                           </a>
                        </li>
                        <li>
                           <a href="/refundandretunpolicy">
                           <i class='bx bx-chevron-right'></i>
                          Refund and Return Policy
                           </a>
                        </li>
                        
                     </ul>
                  </div>
               </div>

               <div class="col-lg-2 col-md-6">
                  <div class="single-widget">
                  <h3>Social Media</h3>
                    <div class="social-area">
                        <ul>
                           <li>
                              <a target="_blank" href="https://www.facebook.com/"><i class="bx bxl-facebook"></i></a>
                           </li>
                           <li>
                              <a target="_blank" href="https://twitter.com/"><i class="bx bxl-twitter"></i></a>
                           </li>
                           <li>
                              <a target="_blank" href="https://www.youtube.com/"><i class="bx bxl-youtube"></i></a>
                           </li>
                           <li>
                              <a target="_blank" href="https://www.instagram.com/"><i class="bx bxl-instagram"></i></a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="single-widget">
                  <h3>Whatsapp No</h3>
                    <div class="">
                        <ul>
                           <li>
                              <a target="_blank" href="https://web.whatsapp.com/send?phone=+91 90425 74600&amp;text=Hi,%20I%20have%20a%20query,%20please%20get%20in%20touch!">
                              <img  src="./assets/images/WhatsApp.svg" class="single-whatapp-img" alt="WhatsApp"/>+91  9159153450</a>
                           </li>
                           
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
      <div class="footer-bottom-area footer-bottom-electronics-area">
         <div class="container">
            <div class="copy-right">
               <p>©2021 All Rights Reserved <a href="https://comtal.in/" target="blank"> Comtal Laptop Rentals</a></p>
            </div>
         </div>
      </div>


</div>
    
);
}
}
export default WhatWeDo;